/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex">
                <div class="">
                  <div class="form-floating mb-4">
                    <p-calendar
                      id="dateMoisPointPresence"
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      view="month"
                      dateFormat="MM yy"
                      v-model="dateMoisPointPresence"
                      name="dateMoisPointPresence"
                    ></p-calendar>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-3 col-sm-12 d-flex">
              <p-dropdown
                class="flex-grow-1 me-2"
                v-model="status"
                :options="statusOptions"
                optionLabel="label"
                optionValue="value"
                :placeholder="'Statut'"
                display="chip"
              />
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning me-1"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(statutLiquidation)="{ item: ppo }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusColor(ppo.statutLiquidation)"
                    v-if="ppo.statutLiquidation"
                    >{{ ppo.statutLiquidation }}
                  </PTag>
                </template>
                <template #cell(isEvalue)="{ item: de }">
                  <span class="text-uppercase">
                    <i
                      v-if="de.isEvalue"
                      v-tooltip.left="'Evaluée'"
                      class="bi bi-check-circle text-success"
                      style="font-size: 1.5rem"
                      v-badge.success="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                    <i
                      v-else
                      v-tooltip.left="'Non évaluée'"
                      class="bi bi-slash-circle text-danger"
                      style="font-size: 1.5rem"
                      v-badge.danger="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                  </span>
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(dateDebut)="{ item: de }">
                  <span class="text-uppercase">{{
                    $dayjs(de.dateDebut).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(dateFin)="{ item: de }">
                  <span class="text-uppercase">{{
                    $dayjs(de.dateFin).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(dateMoisPointPresence)="{ item: de }">
                  <span class="text-uppercase">{{
                    de.dateMoisPointPresence != null
                      ? $dayjs(de.dateMoisPointPresence).format("MMM YYYY")
                      : "-"
                  }}</span>
                </template>
                <template #cell(prenom)="{ item: de }">
                  <span class="text-uppercase">{{ de.prenom }}</span>
                </template>
                <template #cell(posteOccupe)="{ item: de }">
                  <div class="ellipsis" v-tooltip.top="de.posteOccupe">
                    {{ de.posteOccupe | truncate() }}
                  </div>
                </template>
                <template #row-details="row">
                  <div
                    v-if="row.item.statutLiquidation == null"
                    class="d-flex justify-content-end"
                  >
                    <button
                      type="button"
                      class="style_btn btn btn-warning"
                      id="button-query"
                      @click="genererPayementPourTous(row.item)"
                    >
                      Générer les lignes de paiements pour tous
                    </button>
                  </div>
                  <!-- <BeneficiairePayement
                    ref="beneficiairePay"
                    :extraQueryArg="`pointPresenceId=${row.item.id}&typeIndemniteCode=FRAIS_STAGE`"
                  /> -->
                  <!-- pointPresenceId=${row.item.id}& -->
                  <LignePayementParEntreprise
                    ref="beneficiairePay"
                    :hideButtons="row.item.statutLiquidation == null"
                    :extraQueryArg="`typeIndemniteCode=FRAIS_STAGE&liquidation.liquidableType=PointPresence&liquidation.liquidableId=${row.item.id}`"
                    :extraStatisticQueryArg="`pointPresence.id=${row.item.id}`"
                  />
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.top="'Voir les détails de paiements'"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                    <!-- <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.top="'Voir générer les paiements'"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                      Génerer
                    </button>
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.top="'Voir les points de présences'"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                      Payer
                    </button> -->
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "@/mixins/auth-mixin";
import SearchableItemParticipantPayementSelector from "../../../../../common/SearchableItemParticipantPayementSelector.vue";
import LignePayementParEntreprise from "./LignePayementParEntreprise.vue";
export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    entreprise: {
      type: Object,
      default: null,
    },
  },
  components: {
    SearchableItemParticipantPayementSelector,
    LignePayementParEntreprise,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      stat: {},
      activeCessationService: {},
      pointPresence: null,
      dateMoisPointPresence: null,
      dateDeb: null,
      dateFin: null,
      errorMessage: null,
      showAdvancedFilter: false,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      statusOptions: [
        { value: null, label: "Tous" },
        { value: "GENERE", label: "GENERE" },
        { value: "PAYE", label: "PAYE" },
        { value: "PAYE_PARTIEL", label: "PAYE_PARTIEL" },
      ],
      status: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des points de présences",
                subtitle: "Liste des points de présences",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "pointPresences/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des points de présences.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des points de présences",
                subtitle: "Liste des points de présences",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "pointPresences/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des points de présences.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["intituleOffre"],
      searchFieldsOptions: [
        {
          label: "Référence placement",
          value: "referencePlacement",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Titre offre",
          value: "intituleOffre",
          visible: this.$can("filter.telephone", "Azoli"),
        },
      ],
      filterOptions: [],
    };
  },
  created() {
    this.makeQuery();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "referencePlacement", label: "Ref.Placement" },
        { key: "dateMoisPointPresence", label: "Mois" },
        { key: "dateDebut", label: "Début.Cont" },
        { key: "dateFin", label: "Fin.Cont" },
        // { key: "status", label: "Statut" },
        { key: "statutLiquidation", label: "Statut" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    entrepriseId() {
      return this.entreprise != null ? this.entreprise.id : null;
    },
  },
  methods: {
    ...mapActions({
      fetchPresencePosteCountStatistics: "statistic/fetchPresencePosteCountStatistics",
      createOrUpdatePriseService: "priseService/createOrUpdatePriseService",
      createOrUpdateArretContrat: "arretContrat/createOrUpdateArretContrat",
      createOrUpdatePointPresence: "pointPresence/createOrUpdatePointPresence",
      cloturerPointPresence: "pointPresence/cloturerPointPresence",
      validerPointPresence: "pointPresence/validerPointPresence",
      recommanderProfils: "offre/recommanderProfils",
      genererPayementParEntreprise: "liquidation/genererPayementParEntreprise",
    }),
    reloadCurrentPointPresenceDetail() {
      this.$refs.beneficiairePay.reloadData();
    },
    async genererPayementPourTous(pointPresence) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment générer les frais de stage pour tous les bénéficiaires suivant  le point de présence du mois de ${this.$dayjs(
          pointPresence.dateMoisPointPresence
        ).format("MMM YYYY")} ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          //   this.$emit("generate", {
          //     modeGeneration: "FULL"
          //   });
          this.generating = true;
          this.genererPayementParEntreprise({
            modeGeneration: "FULL",
            pointPresence,
            typeIndemniteCode: "FRAIS_STAGE",
            entreprise: this.entreprise,
          })
            .then((data) => {
              this.generating = false;
              this.queryStatistics();
              this.$toast.success("Payement généré avec succès!", {
                position: "top-right",
                duration: 8000,
              });
            })
            .catch(() => {
              this.$toast.error("Opération non réussi!", {
                position: "top-right",
                duration: 8000,
              });
            });
        },
      });
    },
    async genererPayement(pointPresence) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment générer les frais de stage pour les bénéficiaires sélectionnés suivant  le point de présence du mois de ${this.$dayjs(
          pointPresence.dateMoisPointPresence
        ).format("MMM YYYY")} ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$emit("generate", {
            modeGeneration: "PARTIAL",
            beneficiaires: this.$refs.beneficiairePay.getSelectedBeneficiaires(),
          });
        },
      });
    },
    getStatusColor(status) {
      let color = "danger";
      if (status == "GENERE") {
        color = "primary";
      } else if (status == "PAYE") {
        color = "success";
      } else if (status == "PAYE_PARTIEL") {
        color = "warning";
      }
      return color;
    },
    makeQuery() {
      this.queryStatistics();
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "pointPresence",
        search: this.search,
        fetcherMethod: "pointPresence/fetchPointPresences",
        dataGetter: "pointPresence/pointPresences",
        paginationGetter: "pointPresence/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          let paramPlus = ``;

          if (this.dateMoisPointPresence != null) {
            const datePoint = this.$dayjs(this.dateMoisPointPresence).format("MM-YYYY");
            paramPlus += `moisAnnee=${datePoint}`;
          }

          if (this.status != null) {
            paramPlus += `&statutLiquidation=${this.status}`;
          }

          return `placement.entrepriseId=${this.entrepriseId}&status=VALIDE&${paramPlus}`;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    async showcloturerPointPresence(pointPresence) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment clôturer le point de présence du mois de ${this.$dayjs(
          pointPresence.dateMoisPointPresence
        ).format("MMM YYYY")} ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.cloturerPointPresence({
            ...pointPresence,
          }).then((data) => {
            this.$toast.success("Recommandation retirée avec succès!", {
              position: "top-right",
              duration: 8000,
            });
            // this.queryStatistics()
          });
        },
      });
    },
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
