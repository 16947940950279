/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="container- mt-1">
      <p-progress-bar v-if="busy" style="height: 0.33em" mode="indeterminate" />
      <div class="d-flex justify-content-between">
        <div>
          <h5>Génération de ligne de paiement</h5>
          <span class="text-muted"
            >Si un DE n'apparaît pas dans la liste, il n'a probablement pas pris
            service.</span
          >
        </div>
        <div>
          <button
            type="button"
            class="style_btn btn btn-warning me-1"
            id="button-query"
            @click="genererPayement()"
          >
            Générer paiements
          </button>
          <button
            type="button"
            class="style_btn btn btn-warning"
            id="button-query"
            @click="genererPayementPourTous()"
          >
            Générer paiements pour tous
          </button>
        </div>
      </div>
      <p-divider />
      <BeneficiairePayement
        ref="beneficiairePay"
        :extraQueryArg="`placementId=${placement.id}&typeIndemniteCode=${typeIndemniteCode}&${extraQueryArg}`"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "@/mixins/auth-mixin";
import SearchableItemParticipantPayementSelector from "../../../../../common/SearchableItemParticipantPayementSelector.vue";
import BeneficiairePayement from "./BeneficiairePayement.vue";
export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    placement: {
      type: Object,
      default: null,
    },
    typeIndemniteCode: {
      type: String,
      required: true,
    },
  },
  components: {
    SearchableItemParticipantPayementSelector,
    BeneficiairePayement,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      activePriseDeService: {},
      activeCessationService: {},
      activePointPresence: {},
      pointPresence: null,
      dateDeb: null,
      dateFin: null,
      errorMessage: null,
      showAdvancedFilter: false,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      statusOptions: [
        { value: null, label: "Tous" },
        { value: "NON_DEMARRE", label: "NON_DEMARRE" },
        { value: "EN_COURS", label: "EN_COURS" },
        { value: "CLOTURE", label: "CLOTURE" },
        { value: "VALIDE", label: "VALIDE" },
      ],
      status: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des points de présences",
                subtitle: "Liste des points de présences",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "pointPresences/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des points de présences.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des points de présences",
                subtitle: "Liste des points de présences",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "pointPresences/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des points de présences.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["intituleOffre"],
      searchFieldsOptions: [
        {
          label: "Référence placement",
          value: "referencePlacement",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Titre offre",
          value: "intituleOffre",
          visible: this.$can("filter.telephone", "Azoli"),
        },
      ],
      filterOptions: [],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "referencePlacement", label: "Ref.Placement" },
        { key: "intituleOffre", label: "Nom" },
        { key: "dateMoisPointPresence", label: "Mois" },
        // { key: "dateDebut", label: "Début.Cont" },
        // { key: "dateFin", label: "Fin.Cont" },
        { key: "status", label: "Statut" },
        // { key: 'validePar', label: 'Validé par' },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    placementId() {
      return this.placement != null ? this.placement.id : null;
    },
  },
  methods: {
    ...mapActions({
      createOrUpdatePriseService: "priseService/createOrUpdatePriseService",
      createOrUpdateArretContrat: "arretContrat/createOrUpdateArretContrat",
      createOrUpdatePointPresence: "pointPresence/createOrUpdatePointPresence",
      cloturerPointPresence: "pointPresence/cloturerPointPresence",
      validerPointPresence: "pointPresence/validerPointPresence",
      recommanderProfils: "offre/recommanderProfils",
    }),
    reloadCurrentPointPresenceDetail() {
      this.$refs.beneficiairePay.reloadData();
    },
    async genererPayementPourTous() {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment générer les indemnités de ${this.typeIndemniteCode} pour tous les bénéficiaires de la liste actuelle ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$emit("generate", {
            modeGeneration: "FULL",
            beneficiaires: this.$refs.beneficiairePay.getSelectedBeneficiaires(),
          });
        },
      });
    },
    async genererPayement() {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment générer les indemnités de ${this.typeIndemniteCode} pour les bénéficiaires sélectionnés?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$emit("generate", {
            modeGeneration: "PARTIAL",
            beneficiaires: this.$refs.beneficiairePay.getSelectedBeneficiaires(),
          });
        },
      });
    },
    getStatusColor(status) {
      let color = "";
      if (status == "EN_COURS") {
        color = "primary";
      } else if (status == "VALIDE") {
        color = "success";
      } else if (status == "CLOTURE") {
        color = "warning";
      } else if (status == "RECALE") {
        color = "danger";
      } else if (status == "PROPOSE") {
        color = "info";
      } else if (status == "NON_DEMARRE") {
        color = "secondary";
      }
      return color;
    },
    selectionChanged(evt, data) {
      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
