/* eslint-disable vue/no-unused-components */
<template>
  <div :class="{ 'container-fluid': !hideHeader }">
    <div class="statistic-box">
      <div class="row gap-4">
        <div
          class="col p-2 rounded border border-warning"
          v-for="(st, i) in statList"
          :key="i"
        >
          <div>{{ st.typeIndemniteCode }}</div>
          <h2>{{ st.totalMontantPercu.toFixed(3) }}</h2>
          <div class="d-flex">
            <div>
              <span>Montant:</span>
              <h6>{{ st.totalMontant.toFixed(3) }}</h6>
            </div>
            <PDivider layout="vertical" />
            <div>
              <span>Frais Envoi:</span>
              <h6>{{ st.totalCoutTransfert.toFixed(3) }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <template v-if="!hideHeader">
        <div class="bg-white p-2 rounded-4">
          <form class="bg-" method="post" accept-charset="utf-8" action="">
            <div class="row py-0 p-fluid grid formgrid">
              <div class="col field">
                <form class="d-flex">
                  <div class="input-group me-4">
                    <div class="p-inputgroup">
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        :placeholder="'Rechercher par'"
                      />
                      <p-multi-select
                        v-model="mxSearchFields"
                        :options="searchFieldsOptions.filter((el) => el.visible)"
                        optionLabel="label"
                        optionValue="value"
                        :editable="true"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6 col-sm-12 d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <p-dropdown
                      class="flex-grow- me-2"
                      v-model="entrepriseType"
                      :options="entrepriseTypeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Entreprise Azoli'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="entrepriseAudite"
                      :options="entrepriseAuditeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Audité'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="hasContratSigne"
                      :options="hasContratSigneOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Contrat'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="hasOffre"
                      :options="hasOffreOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Offre'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="hasPlacement"
                      :options="hasPlacementOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Placement'"
                      display="chip"
                    />
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-grid gap-2">
                  <a
                    href="#"
                    @click.prevent="mxSubmitQuery"
                    class="style_btn btn btn-primary"
                    id="button-query"
                    ><i class="bi bi-search"></i
                  ></a>
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                  class="style_btn btn btn-warning"
                  id="button-query"
                >
                  <i class="bi bi-filter"></i>
                </button>
              </div>
            </div>
          </form>
          <transition>
            <div class="border p-3 rounded shadow-0 mt-2" v-if="showAdvancedFilter">
              <div class="row p-fluid grid">
                <div class="field col-12 col-md-3 col-sm-12 mb-3">
                  <label for="dateDeb">Date de début</label>
                  <p-calendar
                    placeholder="Date de début"
                    v-model="dateDeb"
                    locale="fr"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <div class="field col-12 col-md-3 col-sm-12 mb-3">
                  <label for="dateFin">Date de fin</label>
                  <p-calendar
                    placeholder="Date de fin"
                    class="flex-grow-1"
                    v-model="dateFin"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <template v-for="(option, index) in filterOptions">
                  <div
                    class="col-12 col-sm-6 col-md-3 col-sm-12 mb-3"
                    v-if="option.visible"
                    :key="index"
                  >
                    <label :for="option.labelField">{{ option.label }}</label>

                    <AsyncMultiSelect
                      v-model="option.value"
                      :multiple="true"
                      :queryUrl="option.queryUrl"
                      :optionLabel="option.labelField"
                      :placeholder="option.label"
                      :emitObject="false"
                      :displayMode="option.displayMode"
                      :searchOptions="[option.labelField]"
                    />
                  </div>
                </template>
              </div>
              <div class="d-flex justify-content-end">
                <!-- <PButton class="p-button-primary p-button- py-1 p-button-sm ms-1" @click.prevent.stop="resetFilterWithFromOffre">
            Matching avec les critères de l'offre
          </PButton> -->
                <PButton
                  class="p-button-danger p-button- py-1 p-button-sm ms-1"
                  @click.prevent.stop="reinitialiserFiltres"
                >
                  Réinitialiser les filtres
                </PButton>
              </div>
            </div>
          </transition>
        </div>
        <p-divider />
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des entreprises</h4>
          </div>
          <div v-if="$can('create', 'Azoli')" class="btn-group"></div>
          <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
            <PSplitButton
              label="Exporter"
              :model="exportActions"
              class="p-button-outlined p-button-primary"
            />
          </div>
          <div class="btn-group ms-4">
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Affichage mode tableau"
              @click.prevent="viewMode = 'card'"
              class="btn style_btn btn-md"
              :class="{
                'btn-success': viewMode == 'card',
                'btn-light': viewMode == 'table',
              }"
            >
              <i class="bi-grid" />
            </button>
            <button
              @click.prevent="viewMode = 'table'"
              class="btn style_btn"
              :class="{
                'btn-light': viewMode == 'card',
                'btn-success': viewMode == 'table',
              }"
            >
              <i class="bi-table" />
            </button>
          </div>
        </div>
      </template>
      <template v-if="!mxLoading">
        <div class="bg-white p-3 border">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              selectable
              @row-selected="onRowSelected"
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <template #head(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(raisonSocial)="data">
                <div class="d-flex align-items-center">
                  <i
                    v-if="data.item.hasContratSigne"
                    v-tooltip.top="'Avec contrat'"
                    class="text-primary bi bi-patch-check-fill pr-2"
                  ></i
                  >&nbsp;
                  <span>{{ data.item.raisonSocial }}</span>
                </div>
              </template>

              <template #cell(ifu)="data">
                <span v-if="!data.item.isCandidatAzoli">{{ data.item.ifu }}</span>
                <span
                  v-else
                  class="text-uppercase"
                  v-tooltip.top="
                    `NPI validé ${data.item.ifu != null ? data.item.ifu : ''}`
                  "
                  style="font-size: 1.2rem"
                  v-badge.primary="data.item.ifu ? 'AZOLI' : ''"
                  >{{ data.item.ifu }}</span
                >
              </template>
              <template #cell(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>
              <template #cell(estSuivi)="{ item }">
                <div class="position-relative">
                  <PTag
                    v-tooltip.left="
                      'Créé par ' +
                      (item.creePar != null ? item.creePar.nomPrenom : 'LUI-MEME')
                    "
                    rounded
                    :severity="
                      item.estSuivi
                        ? item.estAutoAffecte
                          ? 'info'
                          : 'primary'
                        : 'danger'
                    "
                    size="large"
                    >{{
                      item.estSuivi
                        ? item.estAutoAffecte
                          ? "Auto.Affecté"
                          : "Affecté"
                        : "Non.Affecté"
                    }}</PTag
                  >
                </div>
              </template>
              <template #cell(offresCount)="{ item }">
                <div class="position-relative">
                  {{ item.offresCount || 0 }}
                </div>
              </template>
              <template #cell(tauxRemplissageProfil)="{ item }">
                <div class="position-relative">
                  {{ item.tauxRemplissageProfil || 0 }}%
                </div>
              </template>
              <template #cell(phone)="{ item }">
                <div class="position-relative">
                  {{ item.phone }}
                </div>
              </template>
              <template #cell(commune)="{ item }">
                <div class="position-relative" v-tooltip.top="`${item.departement.nom}`">
                  {{ item.commune.nom }}
                </div>
              </template>
              <template #cell(criticite)="{ item }">
                <PTag
                  class="position-relative"
                  v-if="item.criticite"
                  :severity="getCriticiteColor(item.codeCouleurCritique)"
                >
                  {{ item.criticite }}
                </PTag>
                <span v-else>N/A</span>
              </template>
              <template #cell(risque)="{ item }">
                <PTag
                  class="position-relative"
                  v-if="item.risque"
                  :severity="getRisqueColor(item.codeCouleurRisque)"
                >
                  {{ item.risque }}
                </PTag>
                <span v-else>N/A</span>
              </template>
              <template #cell(createdDate)="{ item: prog }">
                <span class="text-uppercase">{{
                  $dayjs(prog.createdDate).format("DD-MM-YYYY HH:mm")
                }}</span>
              </template>
              <template #cell(creePar)="{ item: prog }">
                <span class="text-uppercase" v-if="prog.creePar"
                  >{{ prog.creePar.nom }} {{ prog.creePar.prenom }}</span
                >
              </template>
              <template #row-details="row">
                <PTabView :activeIndex.sync="activeTab">
                  <template>
                    <PTabPanel header="Payements">
                      <!-- <PointPresencePayementList
                        v-if="activeTab == 1"
                        :extraQueryArg="`placement.entreprise=${row.item.id}`"
                      /> -->
                      <PayementList
                        :entreprise="row.item"
                        :extraQueryArg="`ownerObjectId=${row.item.id}`"
                      />
                    </PTabPanel>
                    <PTabPanel header="Point de présence">
                      <PointPresenceList v-if="activeTab == 0" />
                    </PTabPanel>
                    <PTabPanel header="Prises de service">
                      <!-- <FicheEntrepriseEdit
                        v-if="activeTab == 0"
                        :entrepriseId="row.item.id"
                      /> -->
                    </PTabPanel>
                    <PTabPanel header="Dossier">
                      <FicheEntrepriseEdit
                        v-if="activeTab == 0"
                        :entrepriseId="row.item.id"
                      />
                    </PTabPanel>
                  </template>
                </PTabView>
              </template>

              <template #cell(actions)="row">
                <div class="d-flex justify-content-end">
                  <button
                    class="btn btn-sm py-1 btn-outline-primary me-1"
                    title="Vue détaillée"
                    @click="mxToggleDetails(row, tableData)"
                  >
                    <i class="bi bi-journal-check"></i>
                  </button>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "@/mixins/auth-mixin";
import CAutoComplete from "../../../../components/common/CAutoComplete.vue";
import FicheEntrepriseEdit from "../../entreprise/FicheEntrepriseEdit.vue";
import PayementList from "./PayementList.vue";
import PointPresenceList from "../../entreprise/suivi/PointPresenceList.vue";
import AsyncMultiSelect from "../../../../components/common/AsyncMultiSelect.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    hideStatistic: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CAutoComplete,
    AsyncMultiSelect,
    PayementList,
    PointPresenceList,
    FicheEntrepriseEdit,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      statList: [],
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      activeTab: 0,
      activeBlock: null,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      entrepriseTypeOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "AZOLI" },
        { value: false, label: "NON AZOLI" },
      ],
      entrepriseType: null,
      entrepriseAuditeOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "AUDITE" },
        { value: false, label: "NON AUDITE" },
      ],
      entrepriseAudite: null,
      hasContratSigneOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "SIGNE" },
        { value: false, label: "NON SIGNE" },
      ],
      hasContratSigne: null,
      statutEvaluationOptions: [
        { value: null, label: "Tous" },
        { value: "EVALUE", label: "EVALUE" },
        { value: "NON_EVALUE", label: "NON EVALUE" },
      ],
      statutEvaluation: null,
      hasOffreOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "AVEC OFFRE" },
        { value: false, label: "SANS OFFRE" },
      ],
      hasOffre: null,
      hasPlacementOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "AVEC PLACEMENT" },
        { value: false, label: "SANS PLACEMENT" },
      ],
      hasPlacement: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des entreprises",
                subtitle: "Liste des entreprises",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "entreprises/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des entreprises.xlsx",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                fetcherMethod: "entreprises/exportToExcel",
                queryParams: params,
                fileName: "Liste des entreprises.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["raisonSocial"],
      searchFieldsOptions: [
        {
          label: "Raison social",
          value: "raisonSocial",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "IFU",
          value: "ifu",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "RCM",
          value: "rcm",
          visible: this.$can("filter.statusAbeneficiairectivite", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "formeJuridique",
          value: "",
          label: "Forme juridique",
          valueKey: "id",
          dropdown: true,
          multiple: true,
          displayMode: "mega-menu",
          labelField: "code",
          queryUrl: "formeJuridiques",
          visible: true,
        },
        {
          column: "activitePrincipale",
          value: "",
          label: "Activités principale",
          dropdown: true,
          valueKey: "id",
          multiple: true,
          labelField: "libelle",
          displayMode: "classic",
          queryUrl: "naemas",
          visible: true,
        },
        {
          column: "activiteSecondaires",
          value: "",
          label: "Activités secondaires",
          dropdown: true,
          valueKey: "id",
          multiple: true,
          labelField: "libelle",
          displayMode: "classic",
          queryUrl: "naemas",
          visible: true,
        },
        {
          column: "departement",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "mega-menu",
          multiple: true,
          valueKey: "id",
          dropdown: true,
          visible: true,
        },
        {
          column: "commune",
          value: "",
          labelField: "nom",
          multiple: true,
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
        },
        {
          column: "arrondissement",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          multiple: true,
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
        },
        {
          column: "ville.id",
          value: "",
          labelField: "nom",
          multiple: true,
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeEntreprise: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeEntreprise = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      // localStorage.setItem('viewMode', this.viewMode)
    },
    extraQueryArg() {
      this.loadData();
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      contrat: "contrat/contrat",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      let fields = [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "raisonSocial", label: "Dénomination" },
        { key: "nomDirigeant", label: "Dirigeant" },
        { key: "ifu", label: "IFU" },
        // { key: "rccm", label: "RCCM" },
        { key: "phone", label: "Téléphone" },
        { key: "commune", label: "Commune" },
        { key: "nombrePointPresence", label: "Total Pre." },
        { key: "nombrePointPresencePaye", label: "Total PP. Paye." },
      ];

      fields = fields.concat([
        { key: "adresseSiege", label: "Siège" },
        { key: "createdDate", label: "Inscrit Le" },
        "actions",
      ]);

      return fields;
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchContract: "contrat/fetchContract",
      fetchPayementPerIndemniteCountStatistics:
        "statistic/fetchPayementPerIndemniteCountStatistics",
    }),
    loadData() {
      this.queryStatistics();
      this.fetchPayementPerIndemniteCountStatistics({
        extraQueryArg: this.mxGetQueryBuilder(),
      }).then((data) => {
        this.statList = data;
      });
    },
    getStatusColor(activite) {
      let bg = "event-box-pending text-white";

      if (activite.statusActivite == "ACTIF") {
        bg = "event-box-done text-white";
      } else if (activite.statusActivite == "ANNULE") {
        bg = "event-box-missed text-white";
      } else if (activite.statusActivite == "SUSPENDU") {
        bg = "event-box-reported text-white";
      }
      return bg;
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    getRisqueColor(couleur) {
      if (couleur == "vert") {
        return "success";
      } else if (couleur == "jaune") {
        return "warning";
      } else if (couleur == "rouge") {
        return "danger";
      }
      return "";
    },
    getCriticiteColor(couleur) {
      if (couleur == "vert") {
        return "success";
      } else if (couleur == "jaune") {
        return "warning";
      } else if (couleur == "orange") {
        return "orange";
      } else if (couleur == "rouge") {
        return "danger";
      }
      return "";
    },
    statisticBlockSelected({ option, value }) {
      this.activeBlock = { option, value };
      this.loadData();
    },
    genererConvention() {
      console.log("OUROU");
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "entreprise",
        search: this.search,
        fetcherMethod: "entreprise/fetchEntreprises",
        dataGetter: "entreprise/entreprises",
        paginationGetter: "entreprise/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("hasContratSigne", this.hasContratSigne);
          paramPlus = this.mxAddQueryParam("estAudite", this.entrepriseAudite, paramPlus);
          paramPlus = this.mxAddQueryParam("hasOffre", this.hasOffre, paramPlus);
          paramPlus = this.mxAddQueryParam(
            "isCandidatAzoli",
            this.entrepriseType,
            paramPlus
          );
          if (this.activeBlock != null) {
            const { option, value } = this.activeBlock;
            paramPlus = this.mxAddQueryParam(option, value, paramPlus);
          }
          paramPlus = this.mxAddQueryParam("hasPlacement", this.hasPlacement, paramPlus);
          let params = `createdDate=${dateDeb}&createdDate=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    showEntrepriseDossier(entreprise) {
      this.activeEntreprise = { ...entreprise };
      this.$refs.entrepriseDossierViewer.show();
    },
    contratTypeOne(value) {},
    async generateContract(value) {
      console.log("value", value);
      await this.fetchContract({ entreprise: value.id, typeContrat: 1 }).then((data) => {
        console.log("contrat2", data);
        if (data.content.length > 0) {
          this.contractEnterprise = data.content[0];
          if (this.contractEnterprise != undefined) {
            // this.contratTypeOne(this.contractEnterprise);
          }
        } else {
          this.$toast.error("Aucun contrat disponible", {
            position: "bottom-right",
            duration: 5000,
          });
        }
      });
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeEntreprise = { ...demandeur };
      this.$router.push({ name: "espace.ce.de.edit" });
    },
  },
};
</script>

<style></style>
