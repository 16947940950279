/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="statistic-box d-flex gap-4" v-if="stat.totalCoutTransfert">
      <div class="col border rounded rounded-8 border-primary p-2">
        <div class="">Total Allocation</div>
        <h2 class="text-primar">{{ stat.totalMontant.toFixed(3) }}</h2>
      </div>
      <div class="col border rounded border-primary p-2">
        <div>Total Frais Envoi</div>
        <h2 class="text-primar">{{ stat.totalCoutTransfert.toFixed(3) }}</h2>
      </div>
      <div class="col border rounded border-primary p-2">
        <div>Total Percu</div>
        <h2 class="text-primar">{{ stat.totalMontantPercu.toFixed(3) }}</h2>
      </div>
    </div>
    <PDivider />
    <div class="container- mt-1">
      <OperateurPayementSelectorDialog
        ref="operateurPayementSelectorDialog"
        :title="'Selection de l\'opérateur de paiement'"
        @save="payerLidationPayementViaOperateur"
      />
      <PayementLiquidationGeneratorDialog
        ref="payementGeneratorDialog"
        :item="generatorItem"
        @save="generatePayement"
      />
      <PayementMissionGeneratorDialog
        ref="payementMissionGeneratorDialog"
        :item="generatorItem"
        @save="generateMissionPayement"
      />
      <PayementAutresPrestatairesDialog
        ref="payementAutresPrestatairesDialog"
        :item="generatorItem"
        @save="generatePrestationPayement"
      />
      <h2 class="mt-3 text-uppercase mb-3">{{ liquidation.libelle }}</h2>
      <p-progress-bar v-if="busy" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                  </span> -->
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des paiements</h4>
        </div>
        <div v-if="$can('create', 'Azoli')" class="btn-group">
          <!-- <a
            v-if="liquidation != null"
            @click.prevent="genererPayementLiquidationList"
            href=""
            class="style_btn btn btn-warning"
          >
            <b-spinner v-if="generating"></b-spinner>
            <span>Générer les payements</span>
          </a> -->

          <a
            @click.prevent="payerPayementLiquidationList"
            href=""
            class="ml-3 style_btn btn btn-primary"
            >Payer tout/Exporter tout</a
          >
        </div>
        <!-- <a @click.prevent="imprimer" href="" class="ml-3 style_btn btn btn-danger"
          >Imprimer tout</a
        > -->
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <!-- <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          /> -->
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>
      <template v-if="!mxLoading">
        <div class="bg-white p-3 border">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              :no-border-collapse="true"
              selectable
              @row-selected="onRowSelected"
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <template #head(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <!-- <template #cell(id)="data"> PP000{{ data.item.id }} </template> -->

              <template #cell(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>

              <template #cell(createdDate)="{ item: prog }">
                <span
                  v-tooltip.top="$dayjs(prog.createdDate).format('DD-MM-YYYY HH:mm')"
                  class="text-uppercase"
                  >{{ $dayjs(prog.createdDate).format("DD-MM-YYYY") }}</span
                >
              </template>
              <template #cell(tauxReglement)="{ item: prog }">
                <PBadge
                  :value="`${prog.tauxReglement}%`"
                  :severity="
                    prog.tauxReglement == 0
                      ? 'danger'
                      : prog.tauxReglement == 100
                      ? 'success'
                      : 'warning'
                  "
                />
              </template>
              <template #cell(montantTotal)="{ item: prog }">
                {{ prog.montantTotal | currency("", 0, { thousandsSeparator: "," }) }}
              </template>
              <template #cell(montantTotalPercu)="{ item: prog }">
                {{
                  prog.montantTotalPercu | currency("", 0, { thousandsSeparator: "," })
                }}
              </template>
              <template #cell(coutTransfert)="{ item: prog }">
                {{ prog.coutTransfert | currency("", 0, { thousandsSeparator: "," }) }}
              </template>
              <template #cell(nombreTotalPayment)="{ item: prog }">
                {{ prog.nombrePayementSolde }}/{{ prog.nombreTotalPayment }}
              </template>
              <template #cell(statutPayement)="{ item: pay }">
                <PTag
                  :value="pay.statutPayement"
                  :serverity="getStatusPayementColor(pay.statutPayement)"
                />
              </template>

              <template #row-details="row">
                <div class="card bg-white">
                  <b-table
                    show-empty
                    empty-text="Aucune donnée à afficher"
                    :fields="detailsTableFields"
                    striped
                    ref="table2"
                    :items="row.item.payements"
                  >
                    <template #head()="data">
                      <span>{{ data.label }} </span>
                    </template>
                    <template #cell(statutPayement)="data">
                      <PTag
                        :value="data.item.statutPayement"
                        :serverity="getStatusPayementColor(data.item.statutPayement)"
                      >
                      </PTag>
                    </template>
                    <template #cell(montantUnitaire)="{ item: prog }">
                      {{
                        prog.montantUnitaire
                          | currency("", 0, { thousandsSeparator: "," })
                      }}
                    </template>
                    <template #cell(montant)="{ item: prog }">
                      {{ prog.montant | currency("", 0, { thousandsSeparator: "," }) }}
                    </template>
                    <!-- A virtual column -->
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                  </b-table>
                </div>
              </template>

              <template #cell(actions)="row">
                <div class="d-flex justify-content-end">
                  <button
                    v-tooltip="'Voir détail du paiement'"
                    class="btn btn-sm py-1 btn-outline-primary me-1"
                    @click="mxToggleDetails(row, tableData)"
                  >
                    <i class="bi bi-journal-check"></i>
                  </button>
                  <button
                    v-tooltip="'Payer toutes les indeminités en attente'"
                    class="btn btn-sm py-1 btn-warning me-1"
                    v-if="row.item.tauxReglement != 100"
                    @click="payerTouteIndemniteEnAttente(row.item)"
                  >
                    <i class="bi bi-cash"></i>
                  </button>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément
              {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ mxPagination.page * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.conseiller.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Payer une indemnité</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import CAutoComplete from "../../../../components/common/CAutoComplete.vue";
// import PayementLiquidationEditor from '../../../../components/espace/common/payement/editor/PayementLiquidationEditor.vue'
import PayementLiquidationList from "./PayementLiquidationList.vue";
import PayementLiquidationGeneratorDialog from "../../../../components/espace/common/payement/editor/PayementLiquidationGeneratorDialog.vue";
import PayementMissionGeneratorDialog from "../../../../components/espace/common/payement/editor/PayemenMissionGeneratorDialog.vue";
import PayementAutresPrestatairesDialog from "../../../../components/espace/common/payement/editor/PayementAutresPrestatairesDialog.vue";
import OperateurPayementSelectorDialog from "../../../../components/espace/common/payement/editor/OperateurPayementSelectorDialog.vue";
export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    typeIndemniteCode: {
      type: String,
      default: "",
    },
    liquidation: {
      type: Object,
      default: null,
    },
  },
  components: {
    CAutoComplete,
    // PayementLiquidationEditor,
    PayementMissionGeneratorDialog,
    PayementAutresPrestatairesDialog,
    PayementLiquidationGeneratorDialog,
    PayementLiquidationList,
    OperateurPayementSelectorDialog,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      generatorItem: {},
      dateDeb: null,
      activePayementLiquidation: null,
      dateFin: null,
      showAdvancedFilter: false,
      paymentOngoing: false,
      generating: false,
      stat: {},
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      exportActions: [
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
            // this.mxExportToExcel().then(items => {
            //   const data = XLSX.utils.json_to_sheet(items.map(el => ({
            //     ...el,
            //     ...el.azoli,
            //     commune: el.commune.name,
            //     departement: el.departement.name,
            //     village: el.ville.name,
            //     arrondissement: el.arrondissement.name,
            //   })))
            //   const wb = XLSX.utils.book_new()
            //   XLSX.utils.book_append_sheet(wb, data, 'data')
            //   XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
            // })
          },
        },
      ],
      searchFields: ["nomPrenom"],
      searchFieldsOptions: [],
      filterOptions: [
        {
          column: "titre",
          value: "",
          label: "Titre",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "Auteur",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "statusActivite",
          key: "value",
          value: "",
          label: "Statut de l'activité",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "CREE", label: "Créée" },
            { value: "CLOTUREE", label: "Clôturée" },
            { value: "NON_TENUE", label: "Non tenue" },
            { value: "TENUE", label: "Tenue" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "typeActivite",
          key: "code",
          valueKey: "code",
          value: "",
          label: "Type activité",
          dropdown: true,
          url: "/typeActivites",
          visible: this.$can("filter.typeActivite", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    busy() {
      return this.generating || this.paymentOngoing || this.mxLoading;
    },
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "beneficiaire.nom", label: "Nom" },
        { key: "beneficiaire.prenom", label: "Prénom" },
        { key: "beneficiaire.phone", label: "Téléphone" },
        { key: "montantTotal", label: "Montant" },
        { key: "coutTransfert", label: "Frais Trans." },
        { key: "montantTotalPercu", label: "Montant Perçu" },
        { key: "tauxReglement", label: "Taux Règl." },
        { key: "nombreTotalPayment", label: "Nbre. Pay." },
        // { key: "statutPayement", label: "Statut" },
        { key: "createdDate", label: "Date" },
        // { key: "datePayement", label: "Au" },
        // { key: 'createdDate', label: 'Inscrit Le' },
        "actions",
      ];
    },
    detailsTableFields() {
      return [
        { key: "index", label: "#" },
        { key: "id", label: "Ref" },
        { key: "description", label: "Motif" },
        { key: "montantUnitaire", label: "Prix/Unité" },
        { key: "nombreUnite", label: "Nbre Unité" },
        { key: "montant", label: "Total" },
        { key: "statutPayement", label: "Statut" },
        // { key: "createdDate", label: "Date" },
      ];
    },
    emptyPayementLiquidation() {
      return {
        observation: null,
        description: null,
        activiteProspection: null,
        natureContrat: null,
        entreprise: null,
        participant: null,
        metiers: null,
        dateDebut: null,
        dateFin: null,
        typePlacement: null,
        satisfactionDemandeur: null,
        satisfactionEmployeur: null,
        statutPlacement: null,
      };
    },
    tableData() {
      let paymentMap = {};
      return this.mxFilteredItems;
      // this.mxFilteredItems.forEach((item) => {
      //   let items = [];
      //   if (paymentMap[item.phone] != null) items = paymentMap[item.phone].items || [];
      //   paymentMap[item.phone] = {
      //     phone: item.phone,
      //     nom: item.nom,
      //     prenom: item.prenom,
      //     statutPayement: item.statutPayement,
      //     total:
      //       paymentMap[item.phone] == null
      //         ? item.montant
      //         : paymentMap[item.phone].total + item.montant,
      //   };
      //   items.push(item);
      //   paymentMap[item.phone].items = items;
      // });
      // return Object.values(paymentMap);
    },
  },
  methods: {
    ...mapActions({
      createOrUpdatePayementLiquidation:
        "payementLiquidation/createOrUpdatePayementLiquidation",
      genererPayementLiquidation: "liquidation/genererPayementLiquidation",
      genererMissionPayementLiquidations:
        "liquidation/genererMissionPayementLiquidations",
      genererPrestationPayementLiquidations:
        "liquidation/genererPrestationPayementLiquidations",
      payerPayementLiquidations: "liquidation/payerPayementLiquidations",
      payerPayementLiquidationPourTelehone:
        "liquidation/payerPayementLiquidationPourTelehone",
      telechargerPayementLiquidationViaOperatorToExcel:
        "liquidation/telechargerPayementLiquidationViaOperatorToExcel",
      telechargerLiquidationToExcel: "liquidation/telechargerLiquidationToExcel",
      fetchPayementPerLiquidationCountStatistics:
        "statistic/fetchPayementPerLiquidationCountStatistics",
    }),
    imprimer() {
      this.telechargerLiquidationToExcel({
        liquidationId: this.liquidation.id,
        extraQueryArg: this.mxGetQueryBuilder(),
      });
      //http://localhost:8000/api/payementLiquidations/12/exportLiquidationToPdf?size=12&sort=id,desc&dateDeb=2021-12-31&dateFin=2080-12-31&liquidationId=12&page=0
    },
    getNatureIndemniteColor(type) {
      let color = "EXTERNE";
      if (type == "INTERNE") {
        color = "danger";
      }
      return color;
    },
    getStatusPayementColor(status) {
      let color = "primary";
      if (status == "EN_ATTENTE") {
        color = "primary";
      } else if (status == "PAYE") {
        color = "success";
      } else if (status == "PAYE_PARTIEL") {
        color = "warning";
      } else if (status == "REFUSE") {
        color = "danger";
      }
      return color;
    },
    addPayementLiquidation() {
      this.activePayementLiquidation = {
        ...this.emptyPayementLiquidation,
      };
      this.$refs.stageFromationEditor.show();
    },
    genererPayementLiquidationList() {
      this.generatorItem = {
        typeIndemniteCode: this.typeIndemniteCode,
        liquidation: this.liquidation,
      };

      if (this.typeIndemniteCode == "MISSION") {
        this.$refs.payementMissionGeneratorDialog.show();
      } else if (this.typeIndemniteCode == "PRESTATION") {
        this.$refs.payementAutresPrestatairesDialog.show();
      } else {
        this.$refs.payementGeneratorDialog.show();
      }
    },
    generatePayement(generatorInfo) {
      this.$toast.success("Opération réussie!", {
        position: "top-right",
        duration: 8000,
      });

      //   this.generating = true;
      //   this.genererPayementLiquidation({
      //     modeGeneration: generatorInfo.modeGeneration,
      //     typeIndemniteCode: this.typeIndemniteCode,
      //     liquidation: this.liquidation,
      //     entreprise: generatorInfo.entreprise,
      //     beneficiaires: generatorInfo.beneficiaires,
      //   }).then((data) => {
      //     this.generating = false;
      //     this.loadData();
      //   });
      this.loadData();
    },
    generateMissionPayement(generatorInfo) {
      this.generating = true;
      this.genererMissionPayementLiquidations({
        modeGeneration: generatorInfo.modeGeneration,
        typeIndemniteCode: this.typeIndemniteCode,
        liquidation: this.liquidation,
        entreprise: generatorInfo.entreprise,
        mission: generatorInfo.mission,
        beneficiaires: generatorInfo.beneficiaires,
      }).then((data) => {
        this.generating = false;
        this.loadData();
      });
    },
    generatePrestationPayement(generatorInfo) {
      this.generating = true;
      this.genererPrestationPayementLiquidations({
        modeGeneration: generatorInfo.modeGeneration,
        typeIndemniteCode: this.typeIndemniteCode,
        liquidation: this.liquidation,
        prestationInfo: {
          ...generatorInfo.prestationInfo,
        },
        beneficiaires: generatorInfo.beneficiaires,
      })
        .then((data) => {
          this.generating = false;
          this.loadData();
        })
        .catch((err) => {
          this.paymentOngoing = false;
        });
    },
    exportExcelForOperator(item) {
      this.telechargerPayementLiquidationViaOperatorToExcel({
        liquidationId: this.liquidation.id,
        extraQueryArg: `${this.mxGetQueryBuilder()}&operateur=${item.operateur}`,
      });
    },
    async payerLidationPayementViaOperateur(item) {
      if (item.payInside) {
        if (item.ciblePayement == "TOUT") {
          await this.$confirm.require({
            group: "confirmDialog",
            message: `Voulez-vous vraiment payer tous les payements en attente de la liquidation actuellle via l'opérateur ${item.operateur.label}?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
              this.paymentOngoing = true;
              this.payerPayementLiquidations({
                liquidation: this.liquidation,
                operateur: item.operateur,
              }).then((data) => {
                this.paymentOngoing = false;
                this.loadData();
              });
            },
          });
        } else {
          await this.$confirm.require({
            group: "confirmDialog",
            message: `Voulez-vous vraiment payer tous les payements en attente de ${item.beneficiaire.nom} ${item.beneficiaire.prenom} sur le numéro de téléphone ${item.beneficiaire.phone}  via l'opérateur ${item.operateur.label}??`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
              this.paymentOngoing = true;
              this.payerPayementLiquidationPourTelehone({
                liquidation: this.liquidation,
                phone: item.beneficiaire.phone,
                operateur: item.operateur,
              })
                .then((data) => {
                  this.loadData();
                  this.paymentOngoing = false;
                })
                .catch((err) => {
                  this.paymentOngoing = false;
                });
            },
          });
        }
      } else {
        this.exportExcelForOperator(item);
      }
    },
    async payerTouteIndemniteEnAttente(item) {
      this.$refs.operateurPayementSelectorDialog.setPayementOption({
        liquidation: this.liquidation,
        phone: item.beneficiaire.phone,
        beneficiaire: item.beneficiaire,
      });
      this.$refs.operateurPayementSelectorDialog.show();
    },
    async payerPayementLiquidationList() {
      this.$refs.operateurPayementSelectorDialog.setPayementOption({
        ciblePayement: "TOUT",
        liquidation: this.liquidation,
      });

      this.$refs.operateurPayementSelectorDialog.show();
    },
    loadData() {
      this.queryStatistics();
      this.fetchPayementPerLiquidationCountStatistics({
        liquidationId: this.liquidation.id,
        extraQueryArg: this.mxGetQueryBuilder(),
      }).then((data) => (this.stat = data));
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "payementLiquidation",
        search: this.search,
        fetcherMethod: "payementLiquidation/fetchPayementLiquidations",
        dataGetter: "payementLiquidation/payementLiquidations",
        paginationGetter: "payementLiquidation/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);
          return `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}&typeIndemniteCode=${this.liquidation.typeIndemnite.code}`;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$router.push({ name: "espace.conseiller.de.edit" });
    },
    savePayementLiquidation(item) {
      console.log("PayementLiquidation: ", item);
      this.createOrUpdatePayementLiquidation({
        ...item,
        participationProgramme: item.beneficiaires[0],
      });
    },
  },
};
</script>

<style></style>
